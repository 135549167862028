import { useState } from "react";
import { IoArrowForwardCircleOutline } from "react-icons/io5";
import { IoArrowForwardCircleSharp } from "react-icons/io5";


type SubmitButtonProps = {
    disabled?: boolean;
    onPress: () => void;
}

const SubmitButton = ({onPress, disabled=false}: SubmitButtonProps) => {

    const [isHover, setIsHover] = useState<boolean>(false)
    return (
         <button
            onClick={() => {onPress()}}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}

        >
            {disabled?
                 <IoArrowForwardCircleSharp className="text-4xl opacity-50 cursor-default"/>:
                <>
                 {!isHover?
                    <IoArrowForwardCircleSharp className="text-4xl"/>:
                    <IoArrowForwardCircleOutline className="text-4xl" />
                }
                </>
            }

            
        </button>
    )
}

export default SubmitButton;