import { useState } from "react";
import SearchArea from "../../../components/SearchArea";
import SubmitButton from "../../../components/SubmitButton";


type NextQuestionSearchAreaProps = {
    onSearchSubmitted: (query: string) => void;
}

const NextQuestionSearchArea = ({onSearchSubmitted}: NextQuestionSearchAreaProps) => {

    const [currentSearchAreaText, setCurrentSearchAreaText] = useState<string>('')

    return (
        <div className="flex flex-row border-gray-200 rounded-xl border-4 w-[640px] h-[full] px-2 py-2 bg-white shadow-lg">
            <SearchArea 
                onEnterPressed={() => {
                    if (currentSearchAreaText.length > 0) {
                        onSearchSubmitted(currentSearchAreaText)
                    }
                }}
                searchAreaText={currentSearchAreaText}
                onSearchAreaTextChanged={setCurrentSearchAreaText}
                placeholder="Ask follow up."
                className="overflow-auto h-full outline-none w-full font-sans resize-none bg-background scrollbar-thumb-idle scrollbar-thin scrollbar-track-transparent"
            />
            <div className="flex flex-row justify-end">
                <SubmitButton 
                    onPress={() => {
                        if (currentSearchAreaText.length > 0) {
                            onSearchSubmitted(currentSearchAreaText)
                        }
                    }}
                    disabled={currentSearchAreaText === ''}
                />
            </div>
        </div>
    )
}

export default NextQuestionSearchArea;