import QueryHeader from "./QueryHeader";
import SourceDisplay from "./SourceDisplay";
import { SlDocs } from "react-icons/sl"; 
import { RiQuestionAnswerLine } from "react-icons/ri";       
import AnswerDisplay from "./AnswerDisplay";
import { useChatbotQuery } from "../../../hooks/chatbot";
import LoadingSpinner from "../../../components/LoadingSpinner";
import NextQuestionSearchArea from "./NextQuestionSearchArea";
import { createSearchParams, useNavigate } from "react-router-dom";


type AnswerViewerProps = {
    query: string;
}

const AnswerViewer = ({query}: AnswerViewerProps) => {

    const {answer, sources, isLoading, isError} = useChatbotQuery(query)

    const navigate = useNavigate()

    const handleOnSearchSubmitted = (query: string) => {
        navigate({
            pathname: '/search',
            search: createSearchParams({
                q: query
            }).toString()
        })
    }

    const sourceDisplays = sources.map(source => ({
        title: source.source_title,
        subtitle: source.chunk_title,
        content: source.chunk_content,
        link: source.source_url
    }))

    return (<div className="h-svh dark:bg-natural-50 text-black dark:text-black">
        <div className="h-[60px] overflow-hidden">
         <QueryHeader query={query}/>
        </div>
        <div className="flex flex-col overflow-y-scroll h-[calc(100%-120px)] md:h-[calc(100%-60px)]">


            <div className="mx-auto h-full w-full px-[10%] mt-8">
                <span className="text-xl font-semibold">{query}</span>
                <div className="mt-4">
                    <div className="text-xl font-thin mb-2 flex flex-row gap-2 items-center">
                        <SlDocs />
                        <span>Sources</span>
                    </div>
                    {isLoading?
                        <div className="flex flex-row justify-center mt-6 border-gray-200 border-2 h-[80px] md:h-[100px] items-center rounded-md w-full"></div>:

                    <SourceDisplay 
                        sources={sourceDisplays}
                    />
                    }
                    
                </div>

                <div className="mt-4">
                    <div className="text-xl font-thin mb-2 flex flex-row gap-2 items-center">
                        <RiQuestionAnswerLine />
                        <span>Answer</span>
                    </div>

                    {isLoading?
                        <div className="flex flex-row justify-center mt-6 border-gray-200 border-2 h-[150px] md:h-[250px] items-center rounded-md"><LoadingSpinner /></div>:
                        <AnswerDisplay answer={answer}/>
                    }
                </div>
            </div>
            
            {!isLoading&&
                <div className="h-[120px] md:h-[180px]"></div>
            }
        
        </div>

        <div className="md:absolute md:bottom-[20px] w-full h-[60px] flex flex-row justify-center">
                <NextQuestionSearchArea 
                    onSearchSubmitted={(query) => {
                        handleOnSearchSubmitted(query)
                    }}
                />
        </div>
        
    </div>)
}

export default AnswerViewer;