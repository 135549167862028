import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import "preline/preline";
import { IStaticMethods } from "preline/preline";
import { SearchMenu } from './features/SearchMenu';
declare global {
  interface Window {
    HSStaticMethods: IStaticMethods;
  }
}


function App() {
  const location = useLocation();

  useEffect(() => {
    window.HSStaticMethods.autoInit();
  }, [location.pathname]);

  const [windowInnerHeight, setWindowInnerHeight] = useState<number>(window.innerHeight)

  useEffect(() => {

    const handleResize = () => {
      setWindowInnerHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);

  }, []);
  

  return (
      <div className='w-full h-svh flex flex-row justify-center overflow-hidden bg-natural-50 dark:bg-natural-50 text-black dark:text-black'>
          <SearchMenu 
            showAvatar={windowInnerHeight > 430}
          />
      </div>

  );
}

export default App;