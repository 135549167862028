import Markdown from "react-markdown";
import remarkGfm from 'remark-gfm'

type AnswerDisplayProps = {
    answer?: string;
}
const AnswerDisplay = ({answer=''}: AnswerDisplayProps) => {
    return <div className="px-[10px] mb-20">
        <Markdown 
            components={{
                h3(props) {
                    const {node, ...rest} = props
                    return <span className="text-lg font-semibold" {...rest}/>
                },
                h2(props) {
                    const {node, ...rest} = props
                    return <span className="text-xl font-semibold" {...rest}/>
                },
                h1(props) {
                    const {node, ...rest} = props
                    return <span className="text-2xl font-semibold" {...rest}/>
                },
                p(props) {
                    const {node, ...rest} = props
                    return <p className="mt-2" {...rest}/>
                },

                ul(props) {
                    const {node, ...rest} = props
                    return <ul className="list-disc" {...rest}/>
                },

                ol(props) {
                    const {node, ...rest} = props
                    return <ul className="list-decimal" {...rest}/>
                }

            }}
        >{answer}</Markdown>
    </div>
}

export default AnswerDisplay;