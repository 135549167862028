type TopicCardProps = {
    topic: string;
    topicIcon?: any;
    onClick: () => void;
}

const TopicCard = ({topic, onClick, topicIcon=null}: TopicCardProps) => {
    return <div 
        onClick={() => onClick()}
        className="flex flex-row items-center justify-center gap-2 border border-gray-200 shadow-sm rounded-md p-[5px] w-[100%] hover:bg-gray-100 active:bg-gray-100 hover:cursor-pointer font-light">
        <span className="text-2xl">{topicIcon??''}</span>
        {/* <span className="text-sm">{topic}</span> */}
    </div>
}

export default TopicCard;