import { ReactNode, Suspense, lazy } from "react";
import { createBrowserRouter, useSearchParams } from "react-router-dom";
import App from "../App";
import { AnswerViewer } from "../features/AnswerViewer";


const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={<h1>Loading...</h1>}>
        <App />
      </Suspense>
    ),
    errorElement: <h1>Route not found</h1>,
  },
  {
    path: "/search",
    Component: () => {

      const [searchParams, setSearchParams] = useSearchParams();

      return (
        <Suspense fallback={<h1>Loading...</h1>}>
          <AnswerViewer key={`${searchParams.get("q")?? ""}-${Math.random()}`} query={searchParams.get("q")?? ""}/>
        </Suspense>
    )}
  }
]);

export default router;
