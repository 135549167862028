import axios from "axios";
import { useEffect, useState } from "react"
import { json } from "stream/consumers";

type RagSource = {
    source_title: string;
    source_url: string;
    chunk_title: string;
    chunk_content: string;
}

export const useChatbotQuery = (query: string) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false);
    const [answer, setAnswer] = useState<string>('')
    const [sources, setSources] = useState<Array<RagSource>>([])

    useEffect(() => {

        setIsLoading(true)
        setIsError(false)

        axios.post('/chatbot/query', {
            'query': query
        }).then(resp => {
            setIsLoading(false)
            setAnswer(resp.data.answer)
            setSources(resp.data.sources)

        }).catch((e) => {
            console.error(e)
            setIsError(true)
        })

    }, [query])

    return {answer, sources, isLoading, isError}
}