import Avatar from "../../../components/Avarar";

type QueryHeaderProps = {
    query?: string;
}

const QueryHeader = ({query=''}: QueryHeaderProps) => {

    return (
        <header className="z-[999] flex flex-wrap w-full bg-white text-sm py-3 border-2 h-[60px]">
            <nav className="max-w-[85rem] w-full mx-auto px-4 sm:flex sm:items-center sm:justify-between flex flex-row justify-between items-center">
                <a className="flex-none font-semibold text-xl text-black focus:outline-none focus:opacity-80 dark:text-black" href="/" aria-label="Brand">
                    <span className="flex flex-row gap-2 items-center">
                        <Avatar className="size-[32px] md:size-[38px]" src={'https://catalinlup.github.io/images/profile.png'}/>
                        <span className="text-[17px] md:text-[20px]">Catalin Lupau</span>
                    </span>
                </a>
                <div className="relative font-semibold text-xs md:text-[15px] text-right">
                    {query}
                </div>
            </nav>
        </header>
    )
}

export default QueryHeader;