import { twMerge } from "tailwind-merge";

type AvatarProps = {
    src: string;
    className?: string;
}


const Avatar = ({src, className=''}: AvatarProps) => {
    return <img className={twMerge(`inline-block rounded-full size-[38px]`, className)} src={src} alt="Avatar"></img>
}

export default Avatar;