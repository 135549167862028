import { useState } from "react";
import { useNavigate } from "react-router-dom";

type SourceCardProps = {
    title: string;
    subtitle: string;
    content: string;
    link: string;
}

const SourceCard = ({title, subtitle, content, link}: SourceCardProps) => {

    const [isHover, setIsHover] = useState<boolean>(false)


    return (
        <div 
            onMouseEnter={() => {setIsHover(true)}}
            onMouseLeave={() => {setIsHover(false)}}
            onClick={() => {
                window.location.href = link
            }}
            className="relative flex flex-col bg-white hover:cursor-pointer active:bg-gray-50 hover:bg-gray-50 border shadow-sm rounded-xl p-4 md:p-5 dark:bg-white  h-[200px] w-[300px]">
        <h3 className="text-md font-bold text-gray-800 dark:text-gray-800">
            {title}
        </h3>
        <p className="mt-1 text-xs font-medium uppercase text-gray-500 dark:text-gray-500">
            {subtitle}
        </p>
        
        {isHover&&
            <div className="hidden md:block absolute z-[990] top-[110%] left-[50%] bg-white transform translate-x-[-50%] w-[500px] p-4 border-2 border-solid rounded">
                {content}
            </div>
        }
  
        </div>
    )
}

export default SourceCard;