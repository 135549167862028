import Avatar from "../../../components/Avarar";
import MenuSearchArea from "./MenuSearchArea";
import TopicCard from "./TopicCard";
import { MdOutlineSchool } from "react-icons/md";
import { FaLaptopCode } from "react-icons/fa";
import { LuBrainCircuit } from "react-icons/lu";
import { HiOutlineCog } from "react-icons/hi";
import { createSearchParams, useNavigate } from "react-router-dom";

type SearchMenuProps = {
    showAvatar: boolean;
}

const SearchMenu = ({showAvatar}: SearchMenuProps) => {

    const navigate = useNavigate()

    const handleOnSearchSubmitted = (query: string) => {
        navigate({
            pathname: 'search',
            search: createSearchParams({
                q: query
            }).toString()
        })
    }

    return <div className="flex flex-col items-center pt-[20px] w-full md:w-[600px] md:pt-[100px]">

        {showAvatar&&
            <Avatar className="size-[160px] mb-4" src={'https://catalinlup.github.io/images/profile.png'}/>
        }

        <span className="mb-4 text-xl font-light">Ask anything about Catalin...</span>

        <MenuSearchArea onSearchSubmitted={handleOnSearchSubmitted}/>

        <div className="grid grid-cols-2 mt-2 gap-4 w-[90%]">
            <TopicCard 
                onClick={() => {
                    handleOnSearchSubmitted(`What is Catalin's career history?`)
                }}
                topicIcon={<FaLaptopCode />} topic="Career history"/>
            <TopicCard 
                onClick={() => {
                    handleOnSearchSubmitted(`What are Catalin's most noteworthy projects?`)
                }}
                topicIcon={<LuBrainCircuit />} topic="Noteworthy projects"/>
            <TopicCard
                onClick={() => {
                    handleOnSearchSubmitted(`What is Catalin's educational history?`)
                }}
                topicIcon={<MdOutlineSchool />} topic="Education"/>
            <TopicCard 
                onClick={() => {
                    handleOnSearchSubmitted(`What are Catalin's main skills?`)
                }}
                topicIcon={<HiOutlineCog />} topic="Skills"/>
        </div>
    </div>
}

export default SearchMenu;