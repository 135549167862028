import SourceCard from "../../../components/SourceCard";

type SourceDisplayItem = {
    title: string;
    subtitle: string;
    content: string;
    link: string;
}

type SourceDisplayProps = {
    sources?: Array<SourceDisplayItem>;
}

const SourceDisplay = ({sources=[]}: SourceDisplayProps) => {

    const sourceCards = sources.map(source => <SourceCard title={source.title} subtitle={source.subtitle} content={source.content} link={source.link}/>)

    return (
        <div className="flex flex-row gap-2 w-full overflow-x-scroll md:overflow-visible">
            {sourceCards}
        </div>
    )
}

export default SourceDisplay;