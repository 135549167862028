
type SearchAreaProps = {
    searchAreaText: string;
    placeholder: string;
    onSearchAreaTextChanged: (newSearchAreaText: string) => void;
    className?: string;
    onEnterPressed: () => void;
}

const SearchArea = ({searchAreaText, placeholder, onSearchAreaTextChanged, onEnterPressed, className=''}: SearchAreaProps) => {

    return (
              <textarea 
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        onEnterPressed()
                    }
                }}
                value={searchAreaText}
                onChange={(e) => onSearchAreaTextChanged(e.currentTarget.value)}
                placeholder={placeholder}
                className={className}
                >
                </textarea>
    )
}

export default SearchArea;